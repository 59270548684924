<template>
  <div class="set-new-password">
    <div v-if="isVerified" class="set-new-password__container">
      <div class="logo-box">
        <img src="@/assets/images/Amblem2@2x.webp" alt="Glocal Logo"/>
      </div>
      <h1>Set New Password</h1>
      <p>
        Your new password must be different from <br/>
        previously used passwords.
      </p>
      <a-input-password
          required
          type="password"
          id="password"
          placeholder="Enter Password"
          v-model:value="password"
      />
      <a-input-password
          type="password"
          id="confirm-password"
          placeholder="Re-Enter Password"
          v-model:value="confirmPassword"
      />
      <p v-if="errorPassword" class="error">
        {{ errorPassword }}
      </p>
      <p v-if="fieldError" class="error">
        {{ fieldError }}
      </p>
      <a-button class="reset-btn" @click="resetPassword" :loading="loading">
        Reset Password
      </a-button>
      <router-link type="primary" class="link-second btn" to="/">
        <img src="@/assets/icons/navigate_next_black.svg" alt=""/>
        Back to login
      </router-link>
    </div>
    <div v-if="!isVerified" class="set-new-password__container bg-none">
      <h1 class="wait-title">Please wait while verifying</h1>
      <div v-if="!linkError">
        <the-loader></the-loader>
      </div>
      <div v-if="linkError">
        <h3>{{ linkError }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import TheLoader from ".././components/BaseComponents/TheLoader.vue";

export default {
  components: {
    TheLoader,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      isVerified: false,
      errorPassword: "",
      linkError: "",
      loading: false,
      fieldError: ''
    };
  },
  created() {
    this.verifyPassword();
  },
  methods: {
    async verifyPassword() {
      try {
        const payload = {
          uidb64: this.$route.query.uidb64,
          token: this.$route.query.token,
        };
        const response = await this.$store.dispatch(
            "auth/verifyResetPassword",
            payload
        );
        if (response.status === 200) {
          this.isVerified = true;
        }
      } catch (err) {
        this.linkError = "Password reset link expired";
        console.log(err);
      }
    },
    async resetPassword() {
      this.loading = true;
      try {
        if (this.password === this.confirmPassword) {
          const payload = {
            password: this.password,
            uidb64: this.$route.query.uidb64,
            token: this.$route.query.token,
          };
          const response = await this.$store.dispatch("auth/setNewPassword", payload);
          if (response.status === 200) {
            this.loading = false;
            this.$router.replace("/");
          }
        } else {
          this.loading = false;
          this.errorPassword = "Password and Confirm Password does not match";
        }
      } catch (err) {
        this.loading = false;
        Object.values(err.response.data).forEach((val) => {
          this.fieldError = val[0]
        });
      }
    },
  },
};
</script>

<style lang="scss">
.set-new-password {
  padding: 8rem 4rem;
  height: 100vh;
  background-color: #eceef1;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    margin: 0 auto;
    text-align: center;
    width: 40rem;
    background-color: $color-white;
    padding: 5rem 2rem;

    h1 {
      font-family: $font-secondary-bold;
      font-size: 2.4rem;
      text-align: center;
      margin-bottom: 0;
    }

    .wait-title {
      font-size: 3.2rem;
      margin-bottom: 1rem;
    }

    p {
      font-family: $font-secondary;
      font-size: 1.6rem;
      text-align: center;
      opacity: 0.7;
      line-height: 2.2rem;
      margin-bottom: 3rem;
    }

    .ant-input-password {
      border-radius: 0;
      border: 1px solid #24242433;
      padding: 0;
      outline-color: $color-primary;
      width: 100%;
      margin-bottom: 1rem;

      .ant-input {
        background-color: transparent;
        padding: 1rem 1.5rem;
        font-size: 1.6rem;
        line-height: normal;
      }

      .ant-input-suffix {
        padding: 0 1rem;
      }
    }

    .logo-box {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;

      img {
        width: 4rem;
      }
    }

    .error {
      margin-bottom: 0;
      width: 100%;
      opacity: 1;
    }

    .reset-btn {
      margin: 1.5rem 0 2rem;
      font-family: $font-secondary-bold;
      font-size: 1.6rem;
      color: $color-white;
      background-color: $color-primary;
      width: 100%;
      height: 4rem;
      border: none;
      border-radius: 0;

      &:hover,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
    }

    .link-second {
      color: $color-black;
      font-family: $font-secondary-bold;
      font-size: 1.6rem;
      opacity: 0.8;

      img {
        transform: rotate(270deg);
      }
    }
  }

  .bg-none {
    background-color: transparent;
    width: 45rem;
  }
}
</style>
